<template>
  <section class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-12">
          <p class="font_18 has_lightgrey has-text-weight-semibold">
            {{ type === IS_PRODUCT ? 'Producto' : 'Servicio' }}
          </p>
        </div>
        <div class="column is-6">
          <div class="columns is-multiline">
            <div class="column is-5">
              <p class="font_15 has_gray">
                Nombre del producto:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ name }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Cantidad disponible:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ quantity }} {{ type === IS_PRODUCT ? 'piezas' : 'horas' }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Precio unitario:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ formatPrice(price) }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Balance total:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ formatPrice(price * quantity) }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Estatus producto:
              </p>
            </div>
            <div class="column is-7">
              <p
                class="font_15 has-text-weight-semibold"
                :class="[ userStatus ? 'has-text-success' : 'has-text-danger' ]"
              >
                {{ userStatus ? 'Activo' : 'Inactivo' }}
              </p>
            </div>

            <div class="column is-5">
              <p class="font_15 has_gray">
                Estatus Transacción:
              </p>
            </div>
            <div class="column is-7">
              <p
                class="font_15 has_gray has-text-weight-semibold"
                :class="[ status === IS_PENDING ? 'has_warning' : (status === IS_APPROVED ? 'has_success' : 'has_danger') ]"
              >
                {{ transactionStatus }}
              </p>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="columns is-multiline is-vcentered">
            <div class="column is-12">
              <p class="font_18 has_lightgrey has-text-weight-semibold">
                Cantidad solicitada:
              </p>
            </div>
            <div class="column is-5">
              <p class="font_15 has_gray">
                Cantidad:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_success has-text-weight-semibold">
                {{ requestedAmount }} {{ type === IS_PRODUCT ? 'piezas' : 'horas' }}
              </p>
            </div>
            <div class="column is-5">
              <p class="font_15 has_gray">
                Precio total:
              </p>
            </div>
            <div class="column is-7">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ formatPrice(price * requestedAmount) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formatPrice } from '@/helpers/formats'

export default {
  name: 'TransactionData',
  data () {
    return {
      IS_PENDING: 1,
      IS_APPROVED: 2,
      IS_REJECTED: 3,
      IS_PRODUCT: 1,
      type: 1,
      name: null,
      quantity: null,
      price: null,
      userStatus: false,
      requestedAmount: null,
      applicantName: null,
      transactionStatus: false,
      status: 1
    }
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    formatPrice,
    spreadData () {
      this.type = this.dataDetail.product_type
      this.name = this.dataDetail.product_name
      this.quantity = this.dataDetail.product_total_pieces
      this.price = this.dataDetail.product_price
      this.userStatus = this.dataDetail.product_active
      this.requestedAmount = this.dataDetail.requested_amount
      this.applicantName = `${this.dataDetail.user_name} ${this.dataDetail.user_last_name || ''} ${this.dataDetail.user_second_last_name || ''}`
      this.transactionStatus = this.dataDetail.status_name
      this.status = this.dataDetail.status
    }
  },
  beforeMount () {
    this.spreadData()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
  }
  .font_30 {
    font-family: 'Montserrat', sans-serif;
  }
</style>
